import { partnersWithImg } from 'blocks/Partners/Partners';
import ArrowLeft from 'components/icons/ArrowLeft';
import ArrowRight from 'components/icons/ArrowRight';
import { PartnerType } from 'types/types';
import Image from 'next/future/image';
import { useEffect, useState } from 'react';
import styles from './HotelsCarousel.module.css';
import { Heading } from 'components/text';
import Container from 'components/container';

import cx from 'classnames';

import { HotelsCarouselProps } from './types';
import Divider from 'components/divider';

const HotelsCarousel: React.FC<HotelsCarouselProps> = ({
  isPartner = false,
  showControlls = true,
  className,
  mt,
}) => {
  const [hotels, setHotels] = useState<PartnerType[][]>([]);
  const [current, setCurrent] = useState(0);
  const length = hotels.length;

  const recalculate = () => {
    const width = window.innerWidth > 1170 ? 1170 - 60 : window.innerWidth - 60;
    const modules = Math.floor(width / 250);
    const temp = [];
    for (let i = 0; i < partnersWithImg.length; i += modules) {
      temp.push(partnersWithImg.slice(i, i + modules));
    }
    setHotels(temp as PartnerType[][]);
  };

  useEffect(() => {
    recalculate();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', recalculate, false);
  }, []);

  useEffect(() => {
    const width = window.innerWidth > 1170 ? 1170 - 60 : window.innerWidth - 60;
    const slider = document.getElementById('carousel');
    if (slider) {
      slider.scrollTo({
        left: current * width,
        behavior: 'smooth',
      });
    }
  }, [current]);

  const nextSlide = () => {
    setCurrent(current >= length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(
      current === 0
        ? length - 1
        : current > length - 1
        ? length - 2
        : current - 1
    );
  };

  const hotelCardClass = cx(styles.image, { [styles.partner]: isPartner });

  return (
    <Container as="section" className={cx(styles.carousel, className)} mt={mt}>
      <Container className={styles.titleContainer}>
        {isPartner && <Divider mr={60} width={140} color="gold-dark" />}
        <Heading align="center" as="h5" weight="demiBold" fontSize="24px">
          Our prestigious hotel partners
        </Heading>
        {isPartner && <Divider ml={60} width={140} color="gold-dark" />}
      </Container>
      <Container className={styles.container} id="carousel">
        <ul className={styles.content}>
          {hotels.map((hotel, index) => {
            return (
              <li key={index} className={styles.group}>
                {hotel.map((hotel, index) => {
                  return (
                    <div key={index} className={hotelCardClass}>
                      <Image
                        src={hotel.image}
                        alt={hotel.name}
                        width={100}
                        height={100}
                        className={styles.hotelLogos}
                      />
                    </div>
                  );
                })}
              </li>
            );
          })}
        </ul>
      </Container>
      {showControlls && (
        <div className={styles.buttons}>
          <button className={styles.button_prev} onClick={prevSlide}>
            <ArrowLeft />
          </button>
          <button className={styles.button_next} onClick={nextSlide}>
            <ArrowRight />
          </button>
        </div>
      )}
    </Container>
  );
};

export default HotelsCarousel;
